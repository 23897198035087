.react-switch {
	vertical-align: middle;
}

.is_active {
	color: white !important;
}

html,
body,
#app,
#app > div {
	height: 100%;
}
