body {
	font-family: 'Arial', sans-serif;
	background-color: white;
	padding: 20px;
}

.some-network {
	vertical-align: top;
	display: inline-block;
	margin-right: 15px;
	text-align: center;
}

.share-button {
	cursor: pointer;
}

.share-button:hover:not(:active) {
	opacity: 0.75;
}
